import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, BrowserRouter } from "react-router-dom"

import Home from "./components/Home"
import Ekran from "./components/Ekran"
import Cekilis from "./components/Cekilis"
import HomeSuccess from "./components/HomeSuccess"
import Admin from "./components/Admin"

function App() {
  return (
    <div className="App">
        
    <BrowserRouter>
      <Routes>
        <Route  >

          <Route path="/" element={<Home />} />
          <Route path="/signup_success/:code" element={<HomeSuccess />} />
          <Route path="/admin_zeren_2024" element={<Admin />} />
          <Route path="/ekran" element={<Ekran />} />
          <Route path="/cekilis" element={<Cekilis />} />
        
          


        </Route>
      </Routes>
    </BrowserRouter>
    <ToastContainer/>
  </div>
  );
}

export default App;
