
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { TbSquare,TbX, TbSquareDotFilled } from "react-icons/tb";

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import axios from 'axios'
import StackGrid, { transitions, easings  } from "react-stack-grid";
import { Flip } from 'react-toastify';
import zeren_qr from '../assets/zeren_qr.png';
import BgVideo from '../assets/background.mp4';

const { scaleDown, scaleUp, flip, fade, fadeDown, fadeUp, helix } = transitions;

function Ekran() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [items, setitems] = useState([]);




  useEffect(() => {

    axios.get('https://api.muchmorewall.site/ekran').then(function (response) {

      console.log(response.data.items);
      setitems(response.data.items)
      //itemsall = response.data.items

    })

    const interval = setInterval(() => {
      getonemore();
    }, 15000);

    return () => clearInterval(interval);

  }, [])

  const getonemore =  () => {
    
    axios.get('https://api.muchmorewall.site/ekran_1al').then(function (response) {
      console.log(response.data.item)
      
      setitems(prevItems => [response.data.item, ...prevItems.sort( () => Math.random() - 0.5) ]);
      //setitems(prevItems => [response.data.item].concat(prevItems));


      
    })
    
  }






  return (
    <div className='ekranbg'>

      <video autoPlay loop muted className="bg-vid"><source src={BgVideo} type="video/mp4" /> </video>

      <div className='fixed bottom-8 right-8 z-50 bg-white bg-opacity-80 w-40 flex flex-col items-center justify-center rounded-xl p-4'>
        <img src={zeren_qr} width={120} />
        <div className='text-center font-bold mt-2 text-sm'>QR KODU TARATARAK SİZ DE MESAJINIZI YAZIN!</div>
      </div>
      <div className='fixed bottom-8 left-8 z-50 bg-white bg-opacity-80 w-40 flex flex-col items-center justify-center rounded-xl p-4'>
        <img src={zeren_qr} width={120} />
        <div className='text-center font-bold mt-2 text-sm'>QR KODU TARATARAK SİZ DE MESAJINIZI YAZIN!</div>
      </div>


      <StackGrid
        columnWidth={350}
        
        //appear={scaleUp.appear}
        appeared={fade.appeared}
        enter={fade.enter}
        entered={fade.entered}
        leaved={fade.leaved}

        duration={2500}
        gutterWidth={20}
        gutterHeight={20}
        easing={easings.backInOut}
        horizontal={false}

        //enableSSR={true}
        
        
      >


        {items.slice(0,60).map((item, index) => (
          <div key={item.unicodes} className={'kutu'} >
            <div className='font-semibold mb-1'>{item.name.split(" ").map((n)=>n[0]+"**").join(" ")}</div>
            {item.mesaj}
          </div>
        ))}


      </StackGrid>



    </div>

  );
}

export default Ekran;