
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { TbSquare,TbX, TbSquareDotFilled } from "react-icons/tb";

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import zeren_logo_white from'../assets/zeren_logo_white.png';
import axios from 'axios'



function Admin() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [users, setusers] = useState([]);
  const [users_total, setusers_total] = useState(0);
  const [selectedPage, setselectedPage] = useState("null");
  const [pageindex, setpageindex] = useState(0);
  
  const [active_count, setactive_count] = useState(0);
  const [passive_count, setpassive_count] = useState(0);
  const [null_count, setnull_count] = useState(0);
  const [limit, setlimit] = useState(10);
  
  
  //const handleEmail = (e) => { setEmail(e.target.value)}
  
  useEffect(() => {

    getUsers();
  }, [selectedPage,pageindex])

  const getUsers = () => {

    axios.get('https://api.muchmorewall.site/admin/users?status='+selectedPage+'&page='+pageindex).then(function (response) {

      console.log(response.data.members);
      

      setusers(response.data.members);
      setusers_total(response.data.members_total);
      setnull_count(response.data.count_null);
      setactive_count(response.data.count_active);
      setpassive_count(response.data.count_passive);
          

    })

  }



  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }

  const yayinaal = (uid) => {

    axios.get('https://api.muchmorewall.site/admin/user_yayina_al?uid='+uid).then(function (response) {

      getUsers()

    })

    
  }
  const pasifeal = (uid) => {

    axios.get('https://api.muchmorewall.site/admin/user_pasifeal?uid='+uid).then(function (response) {

      getUsers()

    })

    
  }

  const t_d = (unix_timestamp) => {
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    var date = new Date(unix_timestamp * 1000);
    var date_2 = date.getDate();
    return date_2 + "." + months[date.getMonth()] + "." + "2024";
  }


  return (
    <div className='adminbg'>
      <div className='container mx-auto p-4'>

        

        <div className=' mt-10 mb-2'>

          <nav aria-label="Tabs" className="flex justify-between space-x-4">

            <div className=''> 
            
              <a
                key={"null"}
                onClick={() => {setselectedPage('null'); setpageindex(0);}}
                className={classNames(
                  selectedPage === 'null' ? 'bg-green-500 text-white' : 'text-gray-600 hover:text-gray-800',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
              >
                Bekleyenler ({null_count})
              </a>

              
              <a
                key={"active"}
                onClick={() => {setselectedPage('active'); setpageindex(0);}}
                className={classNames(
                  selectedPage === 'active' ? 'bg-green-500 text-white' : 'text-gray-600 hover:text-gray-800',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
              >
                Yayında ({active_count})
              </a>

              <a
                key={"passive"}
                onClick={() => {setselectedPage('passive'); setpageindex(0);}}
                className={classNames(
                  selectedPage === 'passive' ? 'bg-green-500 text-white' : 'text-gray-600 hover:text-gray-800',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
              >
                Pasif ({passive_count})
              </a>

            </div>

            <div>

            <a
                key={"katilim"}
                onClick={() => setselectedPage('katilim')}
                className={classNames(
                  selectedPage === 'katilim' ? 'bg-green-500 text-white' : 'text-gray-600 hover:text-gray-800',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
              >
                Katılımcılar ({active_count})
              </a>

            </div>




          </nav>
            

        </div>


        <div className='mt-10'>

          <table className="min-w-full divide-y divide-gray-200">
            
            <tbody className="bg-white divide-y divide-gray-200">
              {selectedPage === "katilim" ?
              <>
                            {users.map((user,index) => (
                <tr key={user.id}>
                  <td className="p-2 whitespace-nowrap">
                    <div className="flex items-center"> 
                        <div className="text-sm font-medium text-gray-900">{(pageindex*limit) + index+1}</div>
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.name}</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.email} </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.phone}</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900 font-semibold">{user.code}</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{t_d(user.time_created)}</div>
                  </td>


                </tr>
              ))}
              
              </>
              :
              <>
              {users.map((user,index) => (
                <tr key={user.id}>
                  <td className="p-2 whitespace-nowrap">
                    <div className="flex items-center"> 
                        <div className="text-sm font-medium text-gray-900">{(pageindex*limit) + index+1}</div>
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.name}</div>
                    <div className="text-sm text-gray-900">{user.email} - {user.phone}</div>
                    <div className="text-sm text-gray-900">Kod: {user.code}</div>

                    <div className='border mt-2 text-sm p-2 bg-slate-50 mb-3' >{user.mesaj}</div>

                    {(selectedPage === "null" || selectedPage === "passive") &&
                      <button
                        type="button"
                        className="bg-green-500 rounded-md  text-white text-sm p-1 "
                        onClick={() => yayinaal(user.uid)}
                      >
                        Yayınla
                      </button>
                      }


                    {(selectedPage === "null" || selectedPage === "active") &&
                      <button
                        type="button"
                        className="bg-slate-500 rounded-md ml-3 text-white text-sm p-1 "
                        onClick={() => pasifeal(user.uid)}
                      >
                        Pasif'e al
                      </button>
                      }
                  </td>


                </tr>
              ))}
              </>
              }
              
            </tbody>
          </table>
        </div>


        {selectedPage !== "katilim" &&

        <div className='flex justify-center gap-3 mt-10'>
        {[...Array(Math.ceil(users_total/limit)).keys()].map((i,index) => (
              <button key={i} onClick={()=>setpageindex(i)}  className={(pageindex === i ? 'bg-slate-700' : 'bg-slate-400') + ' text-white  px-2 py-1 rounded-lg '}>{i+1}</button>
            ))}
        </div>
        }




      </div>
    </div>

  );
}

export default Admin;