
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { TbSquare,TbX, TbSquareDotFilled } from "react-icons/tb";
import Modal from 'react-modal';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import zeren_logo_white from'../assets/zeren_logo_white.png';
import axios from 'axios'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '60vh',
    color: 'black',

    
  },
};

function Home() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mesaj, setmesaj] = useState("");
  const [mesaj_count, setmesaj_count] = useState(0);
  
  const handleEmail = (e) => { setEmail(e.target.value)}
  const handleName = (e) => { setName(e.target.value)}
  const handlePhone = (e) => { setPhone(e.target.value)}
  const handlemesaj = (e) => { setmesaj(e.target.value); setmesaj_count(e.target.value.length)}

  
  const [kosul_1, setkosul_1] = useState(false);
  const [kosul_2, setkosul_2] = useState(false);

    
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  function openModal1() { setModal1Open(true); }
  function closeModal1() { setModal1Open(false); }
  function openModal2() { setModal2Open(true); }
  function closeModal2() { setModal2Open(false); }

  useEffect(() => {

    /*
    const unsubscribe1 = onValue(ref(db, 'user1_key'), (snapshot) => {
      setuser1_key(snapshot.val())
    });

    const unsubscribe2 = onValue(ref(db, 'user2_key'), (snapshot) => {
      setuser2_key(snapshot.val())
    });

    return () => { unsubscribe1(); unsubscribe2()};

    */

  }, [])

  const validateEmail = (em) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(em);
  }


  
const save = async () => {

  var _ready = true;

  if(kosul_1 === false || kosul_2 === false){
    _ready = false
    showError("Lütfen katılım koşulları ve aydınlatma metinini kabul ediniz!")
  }


  if(name.length < 3){
    _ready = false
    showError("Lütfen isminizi giriniz!")
  }

  

  if(!validateEmail(email)) {
    _ready = false
    showError("Lütfen geçerli bir email adresi giriniz!")
  }
    

  if(phone.length < 10){
    _ready = false
    showError("Lütfen telefon numaranızı kontrol ediniz!")
  }

  
  if(mesaj.length < 40){
    _ready = false
    showError("Mesajınız en az 40, en fazla 150 karakter olmalıdır!")
  }



  if(_ready){
    let data = {
      name : name,
      email : email,
      phone : phone,
      mesaj : mesaj
    };
    console.log(data);
    

    axios.post('https://api.muchmorewall.site/signup',JSON.stringify(data)).then(function (response) {

            
            if(response.data.result === 'Success'){
  
              showSuccess("Kaydınız başarıyla alınmıştır!");
              navigate("/signup_success/"+response.data.code);
  
            }else{
              showError("Bu email adresi zaten kayıtlı, lütfen farklı bir email adresi ile kayıt yaptırın!");
            }

    })

  }
  


}




  return (
    <div className='container mx-auto p-8'>

      <div className='flex justify-center mt-10'>
        <img src={zeren_logo_white} alt="logo" width={140} />
      </div>

      <div className='font-semibold text-2xl mt-10 mb-2'>Hoş geldiniz!</div>
      <div className=' mb-2'>Formu doldurarak mesajınızı yazabilirsiniz.</div>
      
      <div className=' mt-8'>Adınız</div>
      <input type="text" placeholder='Ad, Soyad' value={name} onChange={handleName} className="block w-full mt-1 border border-white pl bgkara p-3 text-white shadow-sm ring-1 ring-inset ring-gray-900   "/>
      
      <div className=' mt-6'>Email Adresiniz</div>
      <input type="text" placeholder='Email' value={email} onChange={handleEmail} className="block w-full mt-1 border border-white pl bgkara p-3 text-white shadow-sm ring-1 ring-inset ring-gray-900   "/>

      <div className=' mt-6'>Telefon Numaranız</div>
      <input type="text" placeholder='5XX-XXX-XX-XX' value={phone} onChange={handlePhone} className="block w-full mt-1 border border-white pl bgkara p-3 text-white shadow-sm ring-1 ring-inset ring-gray-900   "/>

<div className='font-semibold mt-8'>Dünyayı daha sürdürülebilir bir yer haline getirmek için en büyük hayalin nedir?</div>
<div className='font-semibold mt-4'>Bu hayalini nasıl gerçekleştirirdin ve  gerçekleştiğinde sence dünyada nasıl bir değişiklik yaratırdın?</div>


      <textarea
          value={mesaj} onChange={handlemesaj}
          rows={4}
          maxLength={150}
          placeholder='Mesajınız...'
          className="block w-full mt-1 border border-white bgkara p-3 text-white shadow-sm ring-1 ring-inset ring-gray-900   "
          />

      <div className='flex justify-between mt-1'>
        <div className='text-xs text-slate-400 '>{mesaj_count}</div>
        <div className='text-xs text-slate-400 text-end '>En az 40, en fazla 150 karakter</div>
      </div>

      <div className='flex items-start space-x-2 mt-5 text-sm'>
            <button onClick={() => setkosul_1(!kosul_1)}>
              {kosul_1 ? <TbSquareDotFilled size={24} strokeWidth={1.3} /> : <TbSquare size={24} strokeWidth={1.3} />}
            </button>
            <div className='flex-1'><button onClick={openModal1} className='font-semibold '>Katılım Koşullarını</button> okudum, anladım ve kabul ediyorum.</div>
          </div>

          <div className='flex items-start space-x-2 mt-4 text-sm'>
            <button onClick={() => setkosul_2(!kosul_2)}>
              {kosul_2 ? <TbSquareDotFilled size={24} strokeWidth={1.3} /> : <TbSquare size={24} strokeWidth={1.3} />}
            </button>
            <div className='flex-1 '>Kişisel verilerimin işlenmesi ve paylaşılması kapsamında yazılmış <button onClick={openModal2} className='font-semibold'>Aydınlatma Metnini</button> okudum, anladım ve kabul ediyorum.</div>
          </div>

          <div className='my-8'>
            <button className='bg_buto p-4 w-full text-xl font-semibold text-white' onClick={save}>KAYDET</button>  
          </div>

          <Modal isOpen={modal1Open} onRequestClose={closeModal1} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal1}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-semibold mb-8'>Zeren Group Mesaj Duvarı ve Çekiliş Katılım Koşulları</div>
              <ol>
               
<li>Bu aktivite ve yarışma etkinliği 4-8 Kasım 2024 tarihleri arasında MYSTAFF ORGANİZASYON VE ETKİNLİK YÖNETİMİ SANAYİ VE DIŞ TİCARET ANONİM ŞİRKETİ (“Şirket”) tarafından düzenlenmektedir.</li>
<li>Yapılacak yarışma kapsamında verilecek uçak biletlerinin alımı ve her türlü ilgili işlem ve sorumluluk Şirket’e aittir.</li>
<li>Katılımcıların gidecekleri ülkelere ait vize ve benzeri sorumlulukları kendilerine aittir.</li>
<li>Katılım ve Hediye Kazanma Kuralları:</li>
<ul>
<li>Katılımcılar Brand Week Istanbul 2024 etkinlik alanında, 4-8 Kasım 2024 tarihlerinde gerçekleştirilecek olan Mesaj Duvarı Etkinlik ve Yarışma Aktivasyonu (“Yarışma”) esnasında esnasında paylaşılacak QR kod ile aktivite giriş internet sayfasına ulaşır.</li>
<li>Ad, Soyad, Email Adresi ve Telefon Numarası bilgilerini dolduran katılımcılar katılım şartları ve aydınlatma metnini okuyup mesajlarını yazarak kaydolur.</li>
<li>Kayıt tamamlandığında katılımcıya altı haneli bir yarışma numarası verilir.</li>
<li>Katılımcılar tarafından yazılan ve yetkili personel tarafından onaylanan mesajlar etkinlik süresince ekranda gösterilir.</li>
<li>Mesajları onaylanarak yayınlanan katılımcılar etkinlik sonunda düzenlenecek hediye yarışmasına katılmaya hak kazanır.</li>
<li>Aynı email adresi ile sadece bir kez kaydolunup mesaj yazılabilir ve sadece bir kez yarışmaya katılınabilir.</li>
</ul>
<li>Jüri tarafından değerlendirilecek olan ve katılımcılar arasından hayallerini en kreatif şekilde ifade eden kişiler arasından 6 adet katılımcıya burada yer alan koşullar kapsamında birer adet uçak bileti hediye edilecektir.</li>
<li>Başvuru sahibi, yarışma başvuru formunda belirtmiş olduğu bilgilerin doğru ve tam olduğunu ve formda belirttiği bilgilerin eksik ve yanlış olması nedeniyle doğabilecek zararlardan sadece kendisinin sorumlu olduğunu kabul etmiştir.</li>
<li>Yarışma’ya katılım yaşı en az 18’dir. Sadece üniversite öğrencilerinin katılımı mümkündür. 18 yaş altı başvuranların başvurusu ancak velilerinin yazılı ve ıslak imzalı onayı ile değerlendirilmeye alınır.</li>
<li>Şiddet, müstehcenlik, cinsel içerik, pornografi, hakaret, iftira içeren ve nefret suçu dâhil olmak üzere 5237 Sayılı Türk Ceza Kanunu ve ilgili tüm mevzuat uyarınca suç ve hukuka aykırılık teşkil eden içeriğe sahip içerikler başvuru kapsamında değerlendirilmeye alınmayacaktır. Bunlara ilişkin mevzuat gereği doğabilecek her türlü cezai ve hukuki sorumluluk katılımcıya aittir. Yüklenen herhangi bir içerikten doğan herhangi bir şikâyet veya talebin vuku bulması halinde Şirket, ihbar edenlerin ve ihbar edilen kişilerin kimliğini ve bunlar hakkında sahip olmuş veya olabileceği diğer her türlü bilgi ve/veya veriyi ifşa etme hakkını saklı tutar.</li>
<li>Yarışma sonucunda kazanan kişilere kazananın ilanını müteakip 15 gün içerisinde iletişim bilgileri üzerinden ulaşılacaktır. Ulaşım sağlanamaması halinde yedek kazanan listesinde yer alan katılımcılar hak kazanabilecektir.</li>
<li>Yarışmaya sadece gerçek kişiler başvuru yapabilecek olup, yalnızca gerçek kişiler fotoğraf yükleyebilirler. Hiçbir tüzel kişi, kurum, kuruluş ya da topluluk adına yükleme ya da başvuru yapılamaz.</li>
<li>Katılımda sunulan kimlik ve adres bilgilerinin doğruluğu ve yeterliliğinin ispatı katılımcının sorumluluğundadır. Katılımcılar, yarışmaya başvuru esnasında, üçüncü kişilere ait kimlik, adres bilgileri veya diğer kişisel verileri hiçbir şekilde kullanmayacaktır.</li>
<li>Şirket, başvuru sahibinin başvuru formunda ilettiği kişisel verileri 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında yerel ve uluslararası yükümlülüklerini yerine getirmek amacıyla ve/veya yarışma ile ilgili olarak toplayabilir, işleyebilir üçüncü kişilere aktarabilir.</li>
<li>Kazanılan hediye değiştirilemez, devredilemez, iade edilemez ve nakde dönüştürülemez.</li>
<li>15.08.2003 tarih ve 25200 sayılı Resmi Gazetede yayımlanarak yürürlüğe giren 4971 sayılı Kanunun 20&#39;nci maddesi ile değiştirilen “320 sayılı Kanun Hükmünde Kararname”nin 41&#39;inci maddesi ve bu maddeye istinaden çıkartılarak 04.10.2006 tarih ve 26309 sayılı Resmi Gazetede yayımlanarak yürürlüğe giren “Karşılığı Nakit Olmayan Piyangolar ve Yarışmalar Hakkında Yönetmelik” uyarınca katılımcılardan herhangi bir ücret talep edilmemektedir ve bu Yarışma herhangi ticari bir amaç gütmemektedir.</li>
<li>Katılımcı; etkinlik içeriğinden haberdar olduğunu, hediye, teslim şekli ve Yarışma koşullarını bildiğini, bu konuda itiraz ve şikayet haklarından feragat ettiğini kabul etmiş ve bu şekilde yarışmaya katılmıştır.</li>
<li>Şirket, yarışmaya katılım sırasında ve yarışma esnasında oluşabilecek teknik aksaklıklardan dolayı münhasıran sorumludur. Yarışma konusunda tek muhatabın Şirket olduğu katılımcı tarafından kabul edilir.</li>
<li>Yarışmaya katılanlar, Şirket’in işbu Katılım Koşulları’nda tesis ve ilan ettiği kuralları şimdiden kabul ederler. Katılımcıların yarışma kurallarına uyup uymadıklarının denetim ve değerlendirilmesi münhasıran jüri tarafından yapılacaktır.</li>
<li>Etkinliğe katılanlar, yukarıda yazılı ve ilan edilmiş söz konusu etkinlik kural ve şartlarının tümünü kayıtsız şartsız kabul etmiş sayılır.</li>





              </ol>

        </Modal>

        <Modal isOpen={modal2Open} onRequestClose={closeModal2} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal2}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-semibold'>BRANDWEEK İSTANBUL 2024 MESAJ DUVARI ÇEKİLİŞİ</div>
            <div className='text-2xl font-semibold'>KİŞİSEL VERİLERİN KORUNMASINA DAİR AYDINLATMA METNİ ve AÇIK RIZA ONAYI</div>
            <div className='mt-6'>


<div className='mt-2'>İşbu Aydınlatma Metni’nde “Brandweek İstanbul 2024 Mesaj Duvarı Çekilişi” kapsamında kişisel verilerinizin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili mevzuata uygun olarak veri sorumlusu sıfatıyla Mystaff Organizasyon ve Etkinlik Yönetimi Sanayi ve Dış Ticaret Anonim Şirketi (“Şirket”) nezdinde toplanmasına, işlenmesine ve üçüncü kişilere aktarımına ilişkin esaslar belirtilmektedir.</div>
<div className='mt-2 font-semibold'>KİŞİSEL VERİLERİN İŞLENME AMACI</div>
<div className='mt-2'>Çekilişe katılım sağlayan kişilerin ad-soyad, telefon numarası ve e-mail adres bilgileri, Kanun tarafından öngörülen temel ilkelere uygun olarak ve Kanun’un 5. maddesinde belirtilen kişisel veri işleme şartları ve amaçları dâhilinde; aşağıdaki amaçlarla işlenebilecektir;</div>
<div className='mt-2 ml-8'>- Çekilişi kazanan katılımcıların tespiti amacıyla organizasyon ve etkinlik yönetimi,</div>
<div className='mt-2 ml-8'>- Çekilişi kazanan katılımcılar ile iletişim faaliyetlerinin sürdürülebilmesi,</div>
<div className='mt-2 ml-8'>- Çekilişi kazanan katılımcıların hediyelerin organizasyonunun yapılması.</div>
<div className='mt-2 font-semibold'>KİŞİSEL VERİLERİN TOPLANMA YÖNTEM VE HUKUKİ SEBEBİ</div>
<div className='mt-2'>Çekilişe katılan kişilerin ad-soyad, telefon numarası ve e-mail adres bilgileri, Brandweek İstanbul 2024 etkinlik alanına yerleştirilecek bir QR kod aracılığı ile erişilen çevrimiçi form üzerinde, ad, soyad, telefon ve e-posta bilgileri doğrudan tarafınızca doldurulmak suretiyle, Kanun’un 5/2(c) maddesinde düzenlenen bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki sebebiyle işlenmektedir.</div>
<div className='mt-2 font-semibold'>KİŞİSEL VERİLERİN AKTARILDIĞI YERLER VE AKTARIM AMACI</div>
<div className='mt-2'>Çekiliş kazananlarına ait kişisel veriler; KVKK’nın “Kişisel Verilerin Aktarımı” başlıklı 8. maddesindeki kurallara uyularak ve gerekli teknik ve idari tedbirler alınarak, sadece ilgili amacın gerçekleşmesi için gerekli olduğu ölçüde ve yukarıda sayılan amaçlarla sınırlı olarak;</div>
<div className='mt-2 ml-8'>- Hediyelerin temini amacıyla iş ortaklarımıza,</div>
<div className='mt-2 ml-8'>- Hediye uçak biletinin alınabilmesi amacıyla muhtelif havayolu şirketlerine,</div>
<div className='mt-2 ml-8'>- Teknoloji alanındaki altyapı tedarikçilerimiz tarafından sağlanan/kullanımımıza sunulan programlarımıza ve/veya sistemlerimize kaydedilebilmektedir,</div>
<div className='mt-2 ml-8'>- Hukuki yükümlülüğümüzü yerine getirebilmek için yasal düzenlemeler ve mevzuat gereği yetkili kişi ve resmi kurumlara aktarılabilmektedir.</div>
<div className='mt-2 font-semibold'>KANUN KAPSAMINDA İLGİLİ KİŞİNİN HAKLARI</div>
<div className='mt-2'>Kişisel veri sahibi olarak, Kanun’un 11. Maddesi kapsamında aşağıdaki haklara sahipsiniz;</div>
<div className='mt-2 ml-8'>- Kişisel verilerinizin işlenip işlenmediğini öğrenme,</div>
<div className='mt-2 ml-8'>- Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</div>
<div className='mt-2 ml-8'>- Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</div>
<div className='mt-2 ml-8'>- Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</div>
<div className='mt-2 ml-8'>- Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini, düzeltme işleminin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</div>
<div className='mt-2 ml-8'>- Yasal sınırlar hariç olmak üzere Kanunun 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini ve bu durumun kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</div>
<div className='mt-2 ml-8'>- İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendiniz aleyhine bir sonucun ortaya çıkmasına itiraz etme,</div>
<div className='mt-2 ml-8'>- Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.</div>




            </div>
        </Modal>

    </div>

  );
}

export default Home;