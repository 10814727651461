
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';
import confetti from 'canvas-confetti'
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { TbSquare,TbX, TbSquareDotFilled } from "react-icons/tb";

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import axios from 'axios'

import cek1 from'../assets/cek1.png';
import cek2 from'../assets/cek2.png';
import cek3 from'../assets/cek3.png';
import cek4 from'../assets/cek4.png';
import cek5 from'../assets/cek5.png';
import cek6 from'../assets/cek6.png';
import baslat from'../assets/baslat.png';

import zeren_logo_white from'../assets/zeren_logo_white.png';


function Cekilis() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [cekilis1_ad, setcekilis1_ad] = useState("");
  const [cekilis2_ad, setcekilis2_ad] = useState("");
  const [cekilis3_ad, setcekilis3_ad] = useState("");
  const [cekilis4_ad, setcekilis4_ad] = useState("");
  const [cekilis5_ad, setcekilis5_ad] = useState("");
  const [cekilis6_ad, setcekilis6_ad] = useState("");


  const [cekilis1_win_ad, setcekilis1_win_ad] = useState("");
  const [cekilis2_win_ad, setcekilis2_win_ad] = useState("");
  const [cekilis3_win_ad, setcekilis3_win_ad] = useState("");
  const [cekilis4_win_ad, setcekilis4_win_ad] = useState("");
  const [cekilis5_win_ad, setcekilis5_win_ad] = useState("");
  const [cekilis6_win_ad, setcekilis6_win_ad] = useState("");
  

  const [cekilis1_win_code, setcekilis1_win_code] = useState("0");
  const [cekilis2_win_code, setcekilis2_win_code] = useState("0");
  const [cekilis3_win_code, setcekilis3_win_code] = useState("0");
  const [cekilis4_win_code, setcekilis4_win_code] = useState("0");
  const [cekilis5_win_code, setcekilis5_win_code] = useState("0");
  const [cekilis6_win_code, setcekilis6_win_code] = useState("0");

  const [selected, setselected] = useState(1);

  const [countsn, setcountsn] = useState(0);
  const [countnumber, setcountnumber] = useState(123456);
  
  const [show_res, setshow_res] = useState(true);



  useEffect(() => {

    getonemore()

  }, [])

  const getonemore =  () => {


    
    axios.get('https://api.muchmorewall.site/cekilis_yerler').then(function (response) {
      
      setcekilis1_ad(response.data.cekilis[0].name)
      setcekilis2_ad(response.data.cekilis[1].name)
      setcekilis3_ad(response.data.cekilis[2].name)
      setcekilis4_ad(response.data.cekilis[3].name)
      setcekilis5_ad(response.data.cekilis[4].name)
      setcekilis6_ad(response.data.cekilis[5].name)

      setcekilis1_win_ad(response.data.cekilis[0].win_ad)
      setcekilis2_win_ad(response.data.cekilis[1].win_ad)
      setcekilis3_win_ad(response.data.cekilis[2].win_ad)
      setcekilis4_win_ad(response.data.cekilis[3].win_ad)
      setcekilis5_win_ad(response.data.cekilis[4].win_ad)
      setcekilis6_win_ad(response.data.cekilis[5].win_ad)

      setcekilis1_win_code(response.data.cekilis[0].win_code)
      setcekilis2_win_code(response.data.cekilis[1].win_code)
      setcekilis3_win_code(response.data.cekilis[2].win_code)
      setcekilis4_win_code(response.data.cekilis[3].win_code)
      setcekilis5_win_code(response.data.cekilis[4].win_code)
      setcekilis6_win_code(response.data.cekilis[5].win_code)


      setshow_res(true);
      setcountsn(0);

      

    })
    
  }

  var defaults = { startVelocity: 35, spread: 360, ticks: 60, zIndex: 3 };


  const runme = (id) => {

    

    counntt()

  }


  const [interval, setinterval] = useState(null);

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}


  const counntt = () => {
    console.log("counting");

    setshow_res(false)
    
    const iin = setInterval(function() {
      console.log("hello");

      setcountnumber(getRandomArbitrary(123456, 183456))

      setcountsn((...countsn) =>  parseInt(countsn)+1)
      
    }, 80);

    setinterval(iin)

    //
  }

  useEffect(() => {
    if(countsn > 60){
      clearInterval(interval);
      
      /*
      let url = "https://api.muchmorewall.site/cekilis_run"+selected
      axios.get(url).then(function (response) {
        getonemore();
      })
        */
      setcekilis1_win_code("1");
      setshow_res(true);
      setcountsn(0);

      setTimeout(function () {
        setcekilis1_win_code("2");
      }, 6000);

      
      
      let interval2 = setInterval(function() {
        var particleCount = randomInRange(200,300) ;
        
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 } }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 } }));
      }, 300);
      
      setTimeout(() => clearInterval(interval2), 10000);


    }
    
  }, [countsn])
  


  function getRandomArbitrary(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }


  return (
    <div className='cekilisbg flex items-center justify-center'>


        <div>
          {
          show_res === false ? 
          <div className='text-center mt-28'>
            <div className='text-8xl font-semibold '>{countnumber}</div>
          </div>
        :
          
          cekilis1_win_code === "0" ?
              <img onClick={() => runme(1)} src={baslat} alt="logo" className='mt-28' width={240}  />
            :
          cekilis1_win_code === "1" ?
            <div className='text-center mt-36'>
              <div className='text-8xl font-semibold '>??????</div>
              <div className='text-6xl mt-5 font-semibold'>?** ?**</div>
            </div>
            :
            <div className='text-center mt-36' onClick={() => setcekilis1_win_code("0")}>
              <div className='text-8xl font-semibold '>TEBRİKLER</div>
            </div>
          }
        </div>













    </div>

  );
}

export default Cekilis;