
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { TbSquare,TbX, TbSquareDotFilled } from "react-icons/tb";

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import zeren_logo_white from'../assets/zeren_logo_white.png';
import axios from 'axios'



function HomeSuccess() {
  const navigate = useNavigate();
  let { code } = useParams();

  console.log("code");
  console.log(code);
  

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [gun, setGun] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mesaj, setmesaj] = useState("");
  

  useEffect(() => {

    const now = new Date();
    const today = now.getDate();
    setGun(today)

  }, [])






  return (
    <div className='container mx-auto p-8'>

      <div className='flex justify-center mt-10'>
        <a href='/'><img src={zeren_logo_white} alt="logo" width={140} /></a>
      </div>

      <div className='font-semibold text-2xl mt-10 mb-2 text-center'>Teşekkür ederiz.</div>
      <div className=' mt-20 text-center'>Çekiliş katılım numaranız</div>
      <div className='font-semibold text-4xl mt-4 text-center'>{code}</div>
      
      <div className=' mt-20 text-center'>Lütfen katılım numaranızı not edin. </div>
      <div className=' mt-2 text-center'>{gun} Kasım saat 17:00’de gerçekleştirilecek çekilişte kazanma şansınız devam ediyor.</div>
      


    </div>

  );
}

export default HomeSuccess;